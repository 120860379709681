import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Link,
  useTheme,
  ClickAwayListener,
} from '@mui/material';
import { Mention } from 'utils/userTagging';

export type UserTaggingInfo = Mention & {
  access: 'creator' | 'withAccess' | 'withoutAccess';
};

const UserListPicker: React.FC<{
  users: UserTaggingInfo[];
  onUserClick: (user: Mention) => void;
  darkMode: boolean;
  useAbsolutePositioning?: boolean;
  clickAwayHandler?: (event: MouseEvent | TouchEvent) => void;
}> = ({
  users,
  onUserClick,
  darkMode,
  useAbsolutePositioning = true,
  clickAwayHandler = () => {},
}) => {
  const theme = useTheme();

  const { creator, usersWithAccess, usersWithoutAccess } = useMemo(() => {
    const creator = users.find((user) => user.access === 'creator');
    const usersWithAccess = users
      .filter((user) => user.access === 'withAccess')
      .sort((a, b) => a.name.localeCompare(b.name));
    const usersWithoutAccess = users
      .filter((user) => user.access === 'withoutAccess')
      .sort((a, b) => a.name.localeCompare(b.name));

    return { creator, usersWithAccess, usersWithoutAccess };
  }, [users]);

  return (
    <ClickAwayListener mouseEvent='onMouseDown' onClickAway={clickAwayHandler}>
      <Box
        position={useAbsolutePositioning ? 'absolute' : 'relative'}
        bottom={useAbsolutePositioning ? '100%' : undefined}
        left={0}
        right={0}
        bgcolor='white'
        border='1px solid #ccc'
        borderRadius='4px'
        mb={1}
        zIndex={1}
        maxHeight='300px'
        overflow='auto'
        sx={{
          boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.1)', // Shadow only on the top
        }}
        data-testid='user-mention-list-picker'
      >
        <List
          sx={{
            padding: 0,
            backgroundColor: darkMode ? theme.colors.neutral700 : theme.colors.white,
          }}
        >
          {creator && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: darkMode ? theme.colors.neutral500 : theme.colors.neutral200,
                    '& .MuiListItemText-root': {
                      '&:hover': {
                        color: 'inherit',
                      },
                    },
                  },
                }}
                onClick={() => onUserClick(creator)}
              >
                <Box
                  sx={{
                    padding: '.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#D9DEE8',
                    marginRight: '8px',
                    textAlign: 'center',
                    minWidth: '40px',
                  }}
                >
                  {creator.name
                    .split(' ')
                    .filter((name: string) => name.trim().length)
                    .slice(0, 2)
                    .map((name) => name[0])
                    .join('')}
                </Box>
                <ListItemText
                  primary={
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      sx={{
                        color: darkMode ? theme.colors.neutral100 : 'textSecondary',
                      }}
                    >
                      <Typography>{creator.name}</Typography>
                      <Typography
                        fontSize='14px'
                        color={darkMode ? theme.colors.neutral100 : 'textSecondary'}
                      >
                        Task creator
                      </Typography>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}

          {usersWithAccess.map((user) => (
            <ListItem disablePadding key={user.name}>
              <ListItemButton
                onClick={() => onUserClick(user)}
                sx={{
                  padding: '0.5rem 1rem',
                  '&:hover': {
                    backgroundColor: darkMode ? theme.colors.neutral500 : theme.colors.neutral200,
                    '& .MuiListItemText-root': {
                      '&:hover': {
                        color: 'inherit',
                      },
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    padding: '.2rem',
                    borderRadius: '1rem',
                    backgroundColor: '#D9DEE8',
                    marginRight: '8px',
                    textAlign: 'center',
                    minWidth: '40px',
                  }}
                >
                  {user.name
                    .split(' ')
                    .filter((name) => name.trim().length)
                    .slice(0, 2)
                    .map((name) => name[0])
                    .join('')}
                </Box>
                <ListItemText
                  primary={user.name}
                  sx={{
                    fontSize: '14px',
                    color: darkMode ? theme.colors.neutral100 : 'textSecondary',
                    '& .MuiTypography-root': {
                      color: darkMode ? theme.colors.neutral100 : 'textSecondary',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {usersWithoutAccess.length > 0 && (
            <Box
              sx={{
                margin: '0.25rem 1rem',
                fontSize: '14px',
              }}
            >
              <Box display='flex' alignItems='center'>
                <Typography component='span'>🔒</Typography>
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: darkMode ? theme.colors.neutral100 : 'textSecondary',
                  }}
                >
                  The users below don&apos;t have access to this page.
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  sx={{
                    fontSize: '12px',
                    color: darkMode ? theme.colors.neutral100 : 'textSecondary',
                  }}
                >
                  If this is a mistake, contact your admin or{' '}
                  <Link href='/content/feedback' color='primary' underline='hover'>
                    ReStore Support
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
          )}

          {usersWithoutAccess.map((user) => (
            <ListItem disablePadding key={user.name}>
              <ListItemButton disabled>
                <Box
                  sx={{
                    padding: '.2rem',
                    borderRadius: '1rem',
                    backgroundColor: darkMode ? '#B0B1B5' : '#D9DEE8',
                    marginRight: '8px',
                    textAlign: 'center',
                    minWidth: '40px',
                  }}
                >
                  {user.name
                    .split(' ')
                    .filter((name) => name.trim().length)
                    .slice(0, 2)
                    .map((name) => name[0])
                    .join('')}
                </Box>
                <ListItemText
                  primary={
                    <Box display='flex' alignItems='center'>
                      <Typography
                        variant='body1'
                        style={{ color: darkMode ? '#B0B1B5' : theme.colors.neutral500 }}
                        fontSize='14px'
                      >
                        {user.name}
                      </Typography>
                      <Typography variant='body2' sx={{ fontSize: '12px' }}>
                        🔒
                      </Typography>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </ClickAwayListener>
  );
};

export default React.memo(UserListPicker);
